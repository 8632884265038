:root {
  --color-background: #0a192f;

  --color-text: #ccd6f6;

  --color-shine: aqua;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "mid";
  /* outline: 1px solid #cc0000; */
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Font faces */
@font-face {
  font-family: "bold";
  src: url(../src//assets//fonts/Jost600Semi.otf);
}
@font-face {
  font-family: "mid";
  src: url(../src//assets//fonts/Jost500Medium.otf);
}

@font-face {
  font-family: "light";
  src: url(../src//assets//fonts/Jost400Book.otf);
}
@font-face {
  font-family: "lighter";
  src: url(../src//assets//fonts/Jost300Light.otf);
}

.App {
  padding: 0 5rem;
  margin: auto;
  display: flex;
  background-color: var(--color-background);
  height: 100vh;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.top-bar,
.bottom-bar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 10vh;
  align-items: center;
}

.mid-bar {
  display: flex;
  height: 80vh;
  width: 100%;
  outline: 1px solid var(--color-text);
}

.mid-left {
  position: relative;
  width: 35%;
  outline: 1px solid var(--color-text);

  height: 100%;
}
.mid-right {
  width: 65%;
  padding: 0 2rem;
  height: 100%;

  overflow: scroll;
}
.myImgG {
  background-color: var(--color-shine);
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.acc-log > * {
  text-decoration: none;
  color: var(--color-shine);
}
.myImgG:hover {
  cursor: pointer;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.menu {
  display: none;
  width: 3rem;
  height: 3rem;
}
.nav-wrapper > a:hover {
  color: var(--color-shine);
  outline: 1px solid var(--color-text);
}
.nav-wrapper > a {
  padding: 1rem 1.5rem;
  width: 6rem;
  height: 2.5rem;
  font-size: 0.95rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-text);
}
.active-tab {
  color: var(--color-shine) !important;
  text-transform: uppercase;
  font-size: 0.8rem !important;
}
.logo {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: var(--color-shine);
  font-family: "bold";
  text-decoration: none;
  letter-spacing: 0.1rem;
}

.bottom-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bottom-icons > a > svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1rem;
  object-fit: cover;
}
.set {
  width: max-content;
}
.bottom-bar > p {
  font-family: "light";
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
  color: var(--color-text);
}

.main-heading {
  font-size: 2.2rem;
  color: var(--color-text);
  display: flex;
  padding-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  font-family: "bold";
}

.main-heading::after {
  content: "";
  width: 4rem;
  height: 0.4rem;
  display: block;
  background-image: linear-gradient(
    to right,
    var(--color-background),
    var(--color-text)
  );
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sub-heading {
  font-size: 1.5rem;
  margin-top: 2rem;
  color: var(--color-text);
  font-family: "bold";
}

.tooltip {
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  overflow: visible;
  position: relative;
}

.tooltip::before {
  content: attr(data-tooltip);
  font-size: 13px;
  position: absolute;
  left: 20%;
  bottom: 100%;
  background: var(--color-text);
  padding: 5px 10px;
  opacity: 0.5;
  color: #000;
  z-index: 5;
  border-radius: 5px;
  transition: 100ms opacity;
}

.tooltip::after {
  content: "";
  position: absolute;
  left: 25%;
  bottom: 92%;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 0;
  transform: rotate(45deg);
  transition: 100ms opacity;
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
}
.overlay {
  background: var(--color-background);
  cursor: pointer;

  position: absolute;
  opacity: 0.5;
  z-index: 10;
  width: 100%;
  transition: opacity 0.5s;
  height: 100%;
}

.overlay:hover {
  opacity: 0.2;
  transition: opacity 0.5s;
}
/* Responsive it */

@media screen and (max-width: 768px) {
  .App {
    padding: 0 1rem;
    display: flex;
    height: max-content;
  }
  * {
    font-size: 15px;
  }
  .top-bar,
  .bottom-bar {
    height: 5rem;
  }
  .top-bar {
    position: fixed;
    z-index: 50;
    outline: 1px solid var(--color-background);
    background-color: var(--color-background);
    box-shadow: 0 1 0 0 var(--color-background);
  }
  .mid-left {
    margin-top: 1rem;
  }
  .mid-right {
    overflow: unset;
    padding: 3rem 0;
  }
  .mid-left > img {
    height: 25rem;
  }
  .mid-bar {
    flex-direction: column;
    background-color: var(--color-background);
    padding-top: 5rem;
    height: max-content;
    outline: none;
  }
  .mid-left,
  .mid-right {
    outline: none;
    width: 100%;
  }
  .logo {
    font-size: 1.5rem;
  }
  .nav-wrapper {
    padding: 1rem;
    margin-top: 5rem;
    position: absolute;
    top: 0;
    z-index: 11;
    outline: 1px solid var(--color-background);
    background: var(--color-background);
    right: 0;
    gap: 0;

    width: 100%;
    height: 0;
    overflow: hidden;
    flex-direction: column;
  }
  .open {
    height: max-content;
  }
  .menu {
    display: block;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    object-fit: cover;
    padding: 0.6rem;
  }
  .nav-wrapper > * {
    padding: 2rem;
    width: 100%;
    height: 3rem;
  }
  .acc-log {
    font-family: "mid" !important;
    z-index: -2;
  }
  .bottom-bar {
    height: max-content;
    padding: 1rem 0;
    flex-direction: column;
  }
  .tooltip {
    display: none;
  }
  .bottom-icons > img {
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
}
