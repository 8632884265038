@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";
.switch {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  gap: 4px;
}
.switch input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 1px solid var(--color-text);

  min-width: 48px;
  min-height: 22px;
  margin: 0;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.3s, border-color 0.3s;
}
.switch input:checked {
}
.switch input:checked::before {
  left: unset;
  transform: translateX(calc(100% + 12px));
}
.switch input:checked::after {
  content: attr(data-on);
  left: 0.4em;
  right: unset;
}
.switch input::before {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  top: 2px;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-text);
  transition: all 0.3s;
  cursor: pointer;
  z-index: 1;
}
.switch input::after {
  content: attr(data-off);
  color: var(--color-text);
  display: block;
  position: absolute;
  font-size: 10px;
  line-height: 1;
  top: 0.5em;
  right: 0.4em;
  bottom: 0.5em;
}
.switch input:focus {
  outline: 2px solid #222222;
  outline-offset: 2px;
}
