@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";
.home-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.text-content > h1 {
  font-size: 4rem;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-family: "bold";
  letter-spacing: 0.2rem;
}
.text-content > h1::after {
  width: 5rem;
  height: 0.4rem;
  display: block;
  background-image: linear-gradient(
    to right,
    var(--color-background),
    var(--color-text)
  );
  border-radius: 5px;
  content: "";
  display: block;
}

.animated-paragraph {
  margin-left: 0.5rem;

  color: var(--color-shine) !important;
}
.text-content > p {
  color: var(--color-text);
  opacity: 0.9;
  margin-top: 1rem;
  display: flex;
  font-size: 1.7rem;
}
.learn-more {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text);
  width: 10rem;
  font-family: "bold";
  margin-right: 0;
  color: var(--color-background);
  letter-spacing: 0.1rem;
  margin-top: 2rem;
  height: 3rem;
  outline: 1px solid var(--color-text);
  transition: 0.3s all ease-in-out;
  margin-bottom: 3rem;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}

.learn-more:hover {
  outline: 1px solid var(--color-text);
  cursor: pointer;
  background-color: var(--color-background);
  color: var(--color-text);
  opacity: 1;
  padding-right: 1rem;
}
@media screen and (max-width: 768px) {
  .text-content > h1 {
    font-size: 2.5rem;
  }
  .text-content > p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .text-content > learn-more {
    display: none;
  }
}
