@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";

.project-container {
  width: 100%;
}

.project-view-img-holder {
  width: 100%;
  margin-top: 2rem;
  height: max-content;
  position: relative;
  position: relative;
}
.buttonsTo {
  position: absolute;
  bottom: 2rem;
  display: flex;
  gap: 2rem;
  margin-left: 2rem;
}
.buttonsTo > a:hover {
  padding: unset;
  outline: 1px solid var(--color-text);
  opacity: 0.9;
  color: var(--color-shine);
  background-color: var(--color-background);
}
.buttonsTo > a {
  margin: unset;
  color: var(--color-text);
  background-color: var(--color-background);
  outline: 1px solid var(--color-background);
}
.project-view-img-holder > img {
  object-fit: cover;
  width: 100%;
  top: 0;
  left: 0;
  height: 20rem;
}

.project-container > p {
  color: var(--color-text);
  opacity: 0.9;
  margin-top: 1rem;
}
.p-tags {
  margin: 2rem 0;
  color: var(--color-shine) !important;
}
.navigate-projects {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.navigate-projects > * > p:hover {
  color: var(--color-shine);
  cursor: pointer;
}

.navigate-projects > p:hover > img {
  scale: 1.1;
}
.navigate-projects > p:hover {
  color: var(--color-shine);
  cursor: pointer;
}
.navigate-projects > * {
  text-decoration: none;
}
.navigate-projects > * > p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-text);
  font-size: 0.9rem;
  font-family: "bold";
  text-transform: uppercase;
}
.navigate-projects > p {
  display: flex;
  color: var(--color-text);

  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-family: "bold";
  text-transform: uppercase;
}
.arrow {
  height: 2rem;
  width: 1.5rem;
}

@media screen and (max-width: 768px) {
  .buttonsTo {
    width: 100%;
    margin: auto;
  }
  .buttonsTo > a {
    margin: unset;
    width: 50%;
  }
  .navigate-projects > * > p > span {
    display: none;
  }
  .navigate-projects > p > span {
    display: none;
  }
}
