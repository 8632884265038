@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";

.AboutMe {
  width: 100%;
  padding-bottom: 2rem;
}

.AboutMe > p > a:hover {
  text-decoration: underline;
}
.AboutMe > p > a {
  text-decoration: none;
  color: var(--color-shine);

  margin: 0 0.5rem;
}
.AboutMe > p {
  margin-bottom: 1.5rem;
  color: var(--color-text);
  opacity: 0.9;
}
.services-box {
  width: 100%;
  margin: 1rem 0;

  height: max-content;
  display: flex;

  gap: 1rem;
  flex-wrap: inherit;
}
.service-item {
  width: 50%;
  align-items: center;
  padding: 1rem;
  outline: 1px solid var(--color-text);

  gap: 1rem;
  height: 9rem;
  display: flex;
}
.service-item > svg {
  width: 6rem;
  height: 6rem;
  fill: var(--color-text);
  padding: 0.5rem;
}
.service-details > p {
  color: var(--color-text);
  opacity: 0.9;
  font-size: 0.9rem;
  font-family: "mid";
}
.service-details > h1 {
  font-size: 1.1rem;
  font-family: "mid";
  color: var(--color-text);
  margin-bottom: 0.5rem;
}

.service-item {
  position: relative;
  padding: 20px;

  z-index: 1;
}

.service-item::before {
  content: "";
  position: absolute;
  inset: 1px;
  border-radius: inherit;
  z-index: -1;
}
.skills-holder {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  outline: 1px solid var(--color-text);
}

.html-css {
  padding: 1rem;
}
.html-css > p {
  color: var(--color-text);

  font-size: 0.9rem;
}
.bar-empty {
  margin-top: 0.8rem;
  outline: 1px solid var(--color-text);
  width: 100%;
  height: 0.8rem;
  background-image: linear-gradient(to left, #0066ff00, var(--color-text));

  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  padding: 0.2rem;

  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.bar-filled {
  background-image: linear-gradient(to right, #0066ff00, var(--color-text));
  width: 70%;
  height: 100%;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .services-box {
    flex-direction: column;
  }
  .service-item {
    width: 100%;
  }
}
