@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";

.map-div {
  width: 100%;
  margin-top: 2rem;
  height: 25rem;
  background-color: var(--color-text);
  margin-bottom: 2rem;
}
.map-div > iframe {
  all: unset;
  outline: 1px solid var(--color-text);

  width: 100%;
  height: 100%;
  /* filter: grayscale(100%) invert(92%) contrast(83%);
  -webkit-filter: grayscale(100%) invert(92%) contrast(83%); */
}

.top-form {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  flex: 1;
}
input:focus {
  outline: 1px solid var(--color-shine) !important;
}
.top-form > input {
  all: unset;
  outline: 1px solid var(--color-text);
  color: var(--color-text);

  flex: 1 1;
  padding-left: 2rem;
  padding-right: 2rem;

  height: 3rem;
}
.top-form > input:hover {
  outline: 1px solid var(--color-shine);
}
.massage-box {
  display: flex;
  margin-top: 1rem;
  color: var(--color-text);
}
input::placeholder {
  color: var(--color-text);
  opacity: 0.9;
}
.input-message {
  all: unset;
  outline: 1px solid var(--color-text);

  display: flex;
  margin-top: 1rem;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 2rem;

  min-height: 8rem;
}

.input-message::placeholder {
  color: var(--color-text);
  opacity: 0.9;
}

.input-message:hover {
  outline: 1px solid var(--color-shine);
}

.send-message {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  width: 10rem;
  margin-right: 0;
  margin-top: 2rem;
  height: 3rem;
  outline: 1px solid var(--color-text);

  transition: 0.3s all ease-in-out;
  margin-bottom: 3rem;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
.send-message:hover {
  outline: 1px solid var(--color-shine);

  cursor: pointer;
  color: var(--color-shine);
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .top-form {
    flex-direction: column;
    height: max-content;
  }
  .top-form > input {
    padding: 1rem;
  }
}
