@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";
.portfolio-containeer {
  width: 100%;
  @value --color-text from "../../App.css";
  @value --color-shine from "../../App.css";
  @value --color-background from "../../App.css";
}

.filterTab {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.tab {
  all: unset;
  font-size: 0.95rem;
  color: var(--color-text);
  opacity: 0.9;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.tab:hover {
  cursor: pointer;
  color: var(--color-shine);
  opacity: 1;
}
.project-item:hover > h1 {
  color: var(--color-shine);
}
.tab-act {
  color: var(--color-shine) !important;
}
.projects-holder {
  width: 100%;
  flex-wrap: wrap;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
}
.projects-holder > * {
  width: 33%;
  text-decoration: none;
}

.project-item {
  margin-bottom: 2rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  overflow: hidden;
  width: 100%;

  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  padding: 0.21rem;
  -o-transition: 0.3s;
}
.project-item:hover {
  cursor: pointer;
}
.project-item > p {
  font-size: 0.9rem;
  color: var(--color-text);
  opacity: 0.9;
  margin-top: 0.5rem;
}
.project-item > h1 {
  font-size: 1rem;
  color: var(--color-text);
}
.img {
  width: 100%;
  position: relative;

  overflow: hidden;
  height: 12rem;
  margin-bottom: 0.4rem;
}

.project-item .img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.project-item .img > img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .projects-holder > * {
    width: 100%;
    flex-wrap: wrap;
    height: max-content;
  }
  .project-item {
    width: 100%;
  }
  .tab {
    font-size: 1.1rem;
  }
  .filterTab > button:last-child {
    display: none;
  }
  .project-item > p {
    font-size: 1.1rem;
  }
  .project-item > h1 {
    font-size: 1.3rem;
  }
}
