@value --color-text from "../../App.css";
@value --color-shine from "../../App.css";
@value --color-background from "../../App.css";
.Cookies > p {
  color: var(--color-text);
}

.cookie > p {
  color: var(--color-text);
}
.cookie > h1 {
  font-size: 1.1rem;
  font-family: "mid";
  color: var(--color-text);
  margin-bottom: 0.5rem;
}
